.container {
    width: 100vw;
    display: flex;
    height: 100vh;
    position: absolute; 
    z-index: 1002;
    left: 0px;
    overflow: hidden;
    background-color: var(--background-color);
}
 
.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.header {
    display: flex;
    align-items: center;
}
.head {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #333;
}
.loginContainer {
    width: 35vw;
    max-width: 550px;
    border-radius: 12px;
    padding: 20px 30px;
    height: auto;
    background-color: white;
    display: flex;
    justify-content: center;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.25);
    align-items: center;
}
.loginContent {
    padding: 30px 40px;
    width: 100%;
    border-radius: 15px; 
    background-color: white;
    display: flex;
    flex-direction: column; 
} 
.loginHead {
    font-size: 22px;
    font-weight: 500; 
    align-self: flex-start;
    color: #333;
    padding: 10px 0px;  
}
.textField {
    margin: 15px 0px !important;
    z-index: 1;
}
.signIn {
    margin: 10px 0px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    padding: 14px 18px;
    padding-right: 25px;
    font-size: 16px;
    width: 240px;
    justify-content: space-between;
    font-weight: 400;
    border: none;
    letter-spacing: 0.2px;
    border-radius: 4px;
    outline: none;
    overflow: hidden;
    color: white;
    background-color: var(--primary-color);
    position: relative;
    transition: .3s all;
}
.signIn:hover {
    cursor: pointer;
    transform: translateY(-1.5px);
}
.signIn:active {
    cursor: pointer;
    transform: translateY(0.5px);
} 
.flex_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.forget_button{
    color: #176de6;
    cursor: pointer;
    user-select: none;
}
.forget_button:hover{
    text-decoration: underline;
}

@media only screen and (max-width: 1400px) {
    .container::after {
        right: -100px;
        top: -200px;
    }
    .container::before {
        right: -140px;
        top: -150px;
    }
}

@media only screen and (max-width: 1000px) {
    .loginContainer {
        width: 70vw;
    }
}
@media only screen and (max-width: 600px) {
    .loginContainer {
        width: 80vw;
        padding: 15px;
    }
    .loginContent {
        padding: 15px;
    }
}